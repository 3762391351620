
import { Component, Vue } from "vue-property-decorator";
import APIDrawer from "@/components/TheAPIDrawer.vue";
import * as ApiSystemServices from "@/api/helpers/ApiSystem";
import DeletePopover from "@/components/DeletePopover.vue";
import Notify from "@/utils/notifications";
import APISystem from "@/models/APISystem";
import { Params, Count } from "@/api/Gondor";

interface SortableParams {
  column: any;
  prop?: string;
  order: null | "ascending" | "descending";
}

@Component({
  components: {
    DeletePopover,
    APIDrawer,
  },
})
export default class API extends Vue {
  readonly limitArray = [10, 25, 50, 100, 250, 500, 1000];
  readonly defaultParams: Params = {
    limit: 10,
    offset: 0,
    order: "active:DESC",
  };
  readonly defaultSort = {
    prop: "active",
    order: "descending",
  };

  loading = false;
  apiSystems: Count<ApiSystemServices.ApiSystem> = {
    count: 0,
    rows: [],
  };
  currentPage = 1;
  params = { ...this.defaultParams };

  create = false;
  apiSystemDrawer = false;
  apiSystem = new APISystem();

  getApiSystems(page = this.currentPage) {
    this.loading = true;
    this.params.offset = (this.params.limit as number) * (page - 1);
    this.currentPage = page;
    ApiSystemServices.findAll(this.params)
      .then(res => {
        this.apiSystems = res.data.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  mounted() {
    this.getApiSystems();
  }

  created() {
    const index = "10";
    const title = "API'S";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
  }

  redirectDetails(id: any) {
    this.$router.push({
      name: "api-details",
      params: { id: id },
    });
  }

  newDrawer() {
    this.create = true;
    this.apiSystem = new APISystem();
    this.handleDrawer(true);
  }

  editDrawer(index: number) {
    this.create = false;
    const selected = this.apiSystems.rows[index];
    this.apiSystem = {
      id: selected.id,
      active: selected.active,
      leadOffer: selected.leadOffer,
      redirectUrl: selected.redirectUrl,
      apiId: {
        id: selected.api.id,
        name: selected.api.name,
        description: selected.api.description,
        code: selected.api.code,
        clientId: {
          id: selected.api.client.id,
          name: selected.api.client.name,
        },
        offerTypeId: {
          id: selected.api.offerType.id,
          name: selected.api.offerType.name,
        },
      },
      systemId: {
        id: selected.system.id,
        name: selected.system.name,
      },
      pingtree: selected.pingtree,
    } as APISystem;
    this.handleDrawer(true);
  }

  handleDrawer(value: boolean) {
    this.apiSystemDrawer = value;
  }

  sortTable({ column, prop, order }: SortableParams) {
    if (!order) {
      this.params.order = undefined;
    } else {
      const sorter = order === "ascending" ? `${prop}:ASC` : `${prop}:DESC`;
      this.params.order = sorter;
    }

    this.getApiSystems(1);
  }
}
